<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Subscriptions</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Subscription"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="8">
          <v-row align="center" justify="end" no-gutters>
            <!-- <v-btn
              depressed
              color="accent"
              :to="{ name: 'module-paw-subscriptions-temp-stats' }"
            >
              <v-icon left>mdi-chart-pie</v-icon>Temp Subscription Stats
            </v-btn> -->
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="subscriptions"
            sort-by="identifier"
            sort-desc
            show-expand
            no-data-text="No subscriptions found"
          >
            <template v-slot:item.identifier="{ item }">
              <router-link
                :to="{
                  name: 'module-paw-subscriptions-individual',
                  params: { subscriptionId: item.id },
                }"
                >{{ item.identifier }}</router-link
              >
            </template>
            <template v-slot:item.customer="{ item }">
              <router-link
                :to="{
                  name: 'module-paw-customers-individual',
                  params: { customerId: item.customer.id },
                }"
                >{{ item.customer.full_name }}</router-link
              >&nbsp;
              <span class="caption">({{ item.dog_name }})</span>
            </template>
            <template v-slot:item.frequency="{ item }"
              >{{ item.frequency_value }} {{ item.frequency_unit }}</template
            >
            <template v-slot:item.cost="{ item }">£{{ item.cost }}</template>
            <template v-slot:item.status="{ item }">
              <v-chip
                label
                small
                :color="getSubscriptionStatusColor(item.status)"
                >{{ getSubscriptionStatusLabel(item.status) }}</v-chip
              >
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    :to="{
                      name: 'module-paw-subscriptions-individual',
                      params: { subscriptionId: item.id },
                    }"
                  >
                    <v-icon x-small>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>View</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ item }">
              <td :colspan="tableHeaders.length">
                <v-row>
                  <v-col cols="4">
                    <h3>Overview</h3>
                    <v-divider dense></v-divider>
                    <v-simple-table dense>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Gender</strong>
                          </td>
                          <td>{{ item.dog_gender }}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Breed</strong>
                          </td>
                          <td>{{ item.breed.name }}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Volume</strong>
                          </td>
                          <td>{{ item.volume }}kg</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Frequency</strong>
                          </td>
                          <td>
                            {{ item.frequency_value }} {{ item.frequency_unit }}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Product(s)</strong>
                          </td>
                          <td>
                            <ul>
                              <li>{{ item.product1.name }}</li>
                              <li v-if="item.product2">
                                {{ item.product2.name }}
                              </li>
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="7">
                    <h3>Deliveries</h3>
                    <v-divider dense></v-divider>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th>Ref. #</th>
                            <th>Estimated Delivery</th>
                            <th>GA Ref.</th>
                            <th>Status</th>
                            <th class="text-right">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="delivery in item.deliveries"
                            :key="delivery.id"
                          >
                            <td>
                              <router-link
                                :to="{
                                  name: 'module-paw-deliveries-individual',
                                  params: { deliveryId: delivery.id },
                                }"
                              >
                                {{ item.identifier }}{{ delivery.identifier }}
                              </router-link>
                            </td>
                            <td>
                              {{ delivery.formatted_dates.estimated_delivery }}
                            </td>
                            <td></td>
                            <td>
                              <v-chip
                                x-small
                                label
                                :color="
                                  getDeliveryStatusColor(
                                    delivery.delivery_status
                                  )
                                "
                              >
                                {{
                                  getDeliveryStatusLabel(
                                    delivery.delivery_status
                                  )
                                }}
                              </v-chip>
                            </td>
                            <td class="text-right">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    x-small
                                    depressed
                                    color="green lighten-4 green--text"
                                    v-on="on"
                                    :to="{
                                      name: 'module-paw-deliveries-individual',
                                      params: { deliveryId: delivery.id },
                                    }"
                                  >
                                    <v-icon x-small>mdi-eye</v-icon>
                                  </v-btn>
                                </template>
                                <span>View Delivery</span>
                              </v-tooltip>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { paw } from "../../mixins/";

export default {
  mixins: [paw],

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Paw Dog Food",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Subscriptions",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Subscription ID", value: "identifier" },
        { text: "Customer (Dog Name)", value: "customer" },
        { text: "Delivery Frequency", value: "frequency" },
        { text: "Value", value: "cost" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", align: "end" },
        { text: "", value: "data-table-expand" },
      ],
    };
  },

  computed: {
    subscriptions() {
      let subscriptions = this.$store.state.paw["subscriptions"];

      if (this.searchTerm !== "") {
        subscriptions = subscriptions.filter((c) => {
          const dog_name = c.dog_name.toLowerCase();
          const name = c.customer.full_name.toLowerCase();
          const identifier = c.identifier.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return (
            dog_name.includes(searchTerm) ||
            name.includes(searchTerm) ||
            identifier.includes(searchTerm)
          );
        });
      }

      return subscriptions;
    },
  },
};
</script>
