var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-breadcrumbs',{staticClass:"pa-0 pb-2",attrs:{"items":_vm.breadcrumbs,"divider":">"}}),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-12",attrs:{"cols":"auto"}},[_c('h1',[_vm._v("Subscriptions")])]),_c('v-col',{staticClass:"pl-12"},[_c('v-text-field',{attrs:{"label":"Search Subscription","outlined":"","dense":"","hide-details":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-row',{attrs:{"align":"center","justify":"end","no-gutters":""}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.subscriptions,"sort-by":"identifier","sort-desc":"","show-expand":"","no-data-text":"No subscriptions found"},scopedSlots:_vm._u([{key:"item.identifier",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
                name: 'module-paw-subscriptions-individual',
                params: { subscriptionId: item.id },
              }}},[_vm._v(_vm._s(item.identifier))])]}},{key:"item.customer",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
                name: 'module-paw-customers-individual',
                params: { customerId: item.customer.id },
              }}},[_vm._v(_vm._s(item.customer.full_name))]),_vm._v("  "),_c('span',{staticClass:"caption"},[_vm._v("("+_vm._s(item.dog_name)+")")])]}},{key:"item.frequency",fn:function({ item }){return [_vm._v(_vm._s(item.frequency_value)+" "+_vm._s(item.frequency_unit))]}},{key:"item.cost",fn:function({ item }){return [_vm._v("£"+_vm._s(item.cost))]}},{key:"item.status",fn:function({ item }){return [_c('v-chip',{attrs:{"label":"","small":"","color":_vm.getSubscriptionStatusColor(item.status)}},[_vm._v(_vm._s(_vm.getSubscriptionStatusLabel(item.status)))])]}},{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"x-small":"","depressed":"","color":"green lighten-4 green--text","to":{
                    name: 'module-paw-subscriptions-individual',
                    params: { subscriptionId: item.id },
                  }}},on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View")])])]}},{key:"expanded-item",fn:function({ item }){return [_c('td',{attrs:{"colspan":_vm.tableHeaders.length}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('h3',[_vm._v("Overview")]),_c('v-divider',{attrs:{"dense":""}}),_c('v-simple-table',{attrs:{"dense":""}},[_c('tbody',[_c('tr',[_c('td',[_c('strong',[_vm._v("Gender")])]),_c('td',[_vm._v(_vm._s(item.dog_gender))])]),_c('tr',[_c('td',[_c('strong',[_vm._v("Breed")])]),_c('td',[_vm._v(_vm._s(item.breed.name))])]),_c('tr',[_c('td',[_c('strong',[_vm._v("Volume")])]),_c('td',[_vm._v(_vm._s(item.volume)+"kg")])]),_c('tr',[_c('td',[_c('strong',[_vm._v("Frequency")])]),_c('td',[_vm._v(" "+_vm._s(item.frequency_value)+" "+_vm._s(item.frequency_unit)+" ")])]),_c('tr',[_c('td',[_c('strong',[_vm._v("Product(s)")])]),_c('td',[_c('ul',[_c('li',[_vm._v(_vm._s(item.product1.name))]),(item.product2)?_c('li',[_vm._v(" "+_vm._s(item.product2.name)+" ")]):_vm._e()])])])])])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"7"}},[_c('h3',[_vm._v("Deliveries")]),_c('v-divider',{attrs:{"dense":""}}),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Ref. #")]),_c('th',[_vm._v("Estimated Delivery")]),_c('th',[_vm._v("GA Ref.")]),_c('th',[_vm._v("Status")]),_c('th',{staticClass:"text-right"},[_vm._v("Actions")])])]),_c('tbody',_vm._l((item.deliveries),function(delivery){return _c('tr',{key:delivery.id},[_c('td',[_c('router-link',{attrs:{"to":{
                                name: 'module-paw-deliveries-individual',
                                params: { deliveryId: delivery.id },
                              }}},[_vm._v(" "+_vm._s(item.identifier)+_vm._s(delivery.identifier)+" ")])],1),_c('td',[_vm._v(" "+_vm._s(delivery.formatted_dates.estimated_delivery)+" ")]),_c('td'),_c('td',[_c('v-chip',{attrs:{"x-small":"","label":"","color":_vm.getDeliveryStatusColor(
                                  delivery.delivery_status
                                )}},[_vm._v(" "+_vm._s(_vm.getDeliveryStatusLabel( delivery.delivery_status ))+" ")])],1),_c('td',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"x-small":"","depressed":"","color":"green lighten-4 green--text","to":{
                                    name: 'module-paw-deliveries-individual',
                                    params: { deliveryId: delivery.id },
                                  }}},on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View Delivery")])])],1)])}),0)]},proxy:true}],null,true)})],1)],1)],1)]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }